#chat-container {
    position: fixed;
    top: .5rem;
    right: .5rem;
    width: 390px;
    opacity: 0;
    border: 2px solid #aaa;
    border-radius: 15px 15px 0 0;
    transform: scale(0);
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out, width 300ms ease-in-out;
}
  
#chat-container.show {
    opacity: 1;
    transform: scale(1);
}
  
.chat-header {
    border-radius: 15px 15px 0 0;
    border-bottom: 0;
    background: linear-gradient(180deg, rgba(200,200,200,1) 0%, rgba(242,242,242,1) 100%);
}

#chat {
    overflow: auto;
    height: calc(100vh - 9rem);
    border-radius: 0 0 5px 5px;
    border-top: 0;
}

.chat-title {
    padding: 0.5rem;
    font-size: large;
    color: black;
}

main #chat-btn {
    position: fixed;
    text-align: center;
    width: 100%;
    opacity: 0;
    bottom: 1.5rem;
    right: .5rem;
    opacity: 1;
    width: 3rem;
}
