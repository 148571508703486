/* Multi-Select Styles */
.content .multi-select .dropdown-heading {
    width: 200px;
    height: 23px;
    padding-top: -5px !important;
}

.content .container {
    padding-left: 10px !important;
}

.content .dropdown-content {
    padding: 0px !important;
}

.content .select-panel {
    background: #EEE;
}

.content ul {
    padding-left: 10px !important;
    margin-top: 5px !important;
    background: #EEE;
}

.content li {
    padding: 0px !important;
    margin: 0px;
}

.content label {
    background: #EEE;
    padding: 1px !important;
    margin: 0px;
}

.content .item-renderer {
    margin-top: 1px !important;
    vertical-align: middle;
}

.content .dropdown-content {
    background: #EEE;
    width: max-content !important;
    font-weight: normal !important;
}

    .content .dropdown-content .options {
        padding-bottom: 10px;
        padding-top: 5px;
    }

    .content .dropdown-content .select-item {
        width: max-content !important;
        font-weight: normal !important;
        padding-right: 5px !important;
    }

.rmsc .select-item.selected {
    background: #eee !important;
}

.rmsc .dropdown-container {
    background: #eee !important;
}

.rmsc .dropdown-content .panel-content {
    background: #eee !important;
}

.content select option {
    background: #EEE;
}

.csv-selector {
    display: inline-block;
    background: #DDD;
    border-radius: 10px;
    margin: 10px;
    min-height: 25px;
}

    .csv-selector .label {
        padding: 10px;
    }

    .csv-selector .select {
        text-align: right;
        margin-left: 15px;
        margin-right: 0px;
        padding: 5px;
    }


.btn-small {
    font-size: 0.875em !important;
}

.ms-page-list {
    padding: 0px;
    margin-left: 8px;
    margin-top: 10px;
    margin-right: 40px;
    color: #479BD7;
}

    .ms-page-list .item {
        display: inline;
        border: black 1px solid;
        background-color: #EEE;
        margin-right: 5px;
        padding: 5px;
        border-radius: 15px;
        text-align: center;
    }

        .ms-page-list .item span {
            text-align: center;
        }

        .ms-page-list .item:hover {
            background-color: #fff;
            cursor: pointer;
        }

    .ms-page-list .active {
        background-color: #fff !important;
        font-weight: bold;
    }
