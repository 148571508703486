a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


@media all and (min-width: 992px) {
    .dropdown-menu li {
        position: relative;
    }

    .nav-item .submenu {
        display: none;
        position: absolute;
        left: 100%;
        top: -7px;
    }

    .nav-item .submenu-left {
        right: 100%;
        left: auto;
    }

    .dropdown-menu > li:hover {
        background-color: #f1f1f1
    }

        .dropdown-menu > li:hover > .submenu {
            display: block;
        }
}
/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
    .dropdown-menu .dropdown-menu {
        margin-left: 0.7rem;
        margin-right: 0.7rem;
        margin-bottom: .5rem;
    }
}


.otis-menu {
    margin: 0;
    padding: 0;
    height: 25px;
    margin-top: 10px;
    display: inline-block;
    list-style: none;
    background-color: #01488f;
    text-decoration: none;
    font-size: 13px !important;
}

    .otis-menu li {
        position: relative;
    }

        .otis-menu li a {
            color: #ffffff;
            text-align: center;
            text-decoration: none;
            padding: 0;
            margin-top: -2px;
            display: block;
        }

        .otis-menu li ul {
            position: absolute;
            z-index: 9999 !important;
            padding: 0;
            font-weight: 400;
            list-style: none;
            display: none;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            background-color: #fff;
        }

            .otis-menu li ul li a {
                text-align: left;
                color: #01488f !important;
                padding: 8px 15px;
                display: block;
                white-space: nowrap;
            }

                .otis-menu li ul li a:hover {
                    color: #01488f !important;
                    background-color: #ddd;
                }

            .otis-menu li ul li ul {
                left: 100%;
                top: 0;
            }

    .otis-menu a.childmenu:after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent
    }

    .otis-menu a.subchildmenu:after {
        position: absolute;
        right:12px;
        top: 12px;
        vertical-align: 1.255em;
        content: "";
        border-top: .3em solid transparent;
        border-right: 0;
        border-bottom: .3em solid transparent;
        border-left: .3em solid
    }



ul li:hover > ul {
    display: block;
}
