.report-about {
    margin-top: 40px;
}
    .report-about ul {
        background: #FFF;
    }

.
.report-no-filter {
    margin: 40px;
    font-size: 19px;
    color: red;
}


.report-filters {
    background-color: #fff;
    border-radius: 15px;
    padding-left: 10px;
    margin-bottom: 15px;
    font-size: 10px;
}

    .report-filters select {
        font-size: 10px;
        line-height: 1.1;
    }


.report-filters-page3 {
    background-color: #fff;
    border-radius: 15px;
    padding-left: 10px;
    padding-top: 15px;
}

.report-results {
    font-size: 12px;
    padding-left: 10px;
    padding-bottom: 10px;
}

.report-embedded {
    font-size: 12px !important;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0px;
}

    .report-embedded .title {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .report-embedded .subtitle {
        font-size: 16px;
        margin-bottom: 5px;
        color: #479BD7;
        font-weight: bold;
    }

    .report-embedded .reportdiv {
        border: 1px solid #aaa;
        border-radius: 15px;
        padding: 5px;
        margin: 5px;
    }

    .report-embedded .reporttitle {
        font-size: 14px;
        margin-bottom: 0px;
        margin-top: 10px;
        color: #479BD7;
        font-weight: bold;  
        text-align: center;
    }

    .report-embedded hr {
        border: 1px dotted #000;
        border-style: none none dotted;
        color: #000;
        background-color: #fff;
    }

    .report-embedded .nav-tabs {
        font-size: 14px;
        font-style: normal !important;
    }

    .report-embedded .tab-pane {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
    }

/* Tier Three Page Styles */
.report-page {
    overflow: auto;
    padding-bottom: 40px;
}

    .report-page .content {
        background-color: white;
        border: 1px solid #aaa;
        padding: 10px;
        overflow: hidden;
        border-radius: 15px;
    }

        .report-page .content select {
            width: 200px;
        }

        .report-page .content .filter {
            float: left;
            padding-right: 10px;
        }

/* Tier Three Page Styles */
.three-tier-closed {
    height: 0px !important;
}

.three-tier-open {
    height: 100% !important;
    width: 100% !important;
}

.three-tier {
    height: auto;
    max-height: 100%;
    overflow: auto;
    padding-bottom: 0px;
}

    .three-tier .title {
        font-size: 16px;
    }

    .three-tier .report {
        margin-top: 0px;
        margin-left: 5px;
    }

    .three-tier .left-nav {
        background-color: #fff;
        width: 250px;
        float: left !important;
        padding: 5px;
        margin-right: 10px;
        border: 1px solid #aaa;
        border-radius: 15px;
        padding-bottom: 20px;
    }

        .three-tier .left-nav .section-header {
            border-bottom: 1px solid black;
            padding: 5px;
            padding-top: 10px;
            margin-bottom: 8px;
        }

        .three-tier .left-nav .section-item {
            padding: 5px;
            cursor: pointer;
            margin-left: 5px;
        }

            .three-tier .left-nav .section-item:hover {
                background-color: #eee;
            }

        .three-tier .left-nav .active {
            background-color: #eee;
        }

    .three-tier .content {
        background-color: white;
        border: 1px solid #aaa;
        padding: 10px;
        overflow: hidden;
        min-height: calc(100vh - 240px);
        border-radius: 15px;
    }

        .three-tier .content select {
            width: 200px;
        }

.content .filter {
    float: left;
    padding-right: 10px;
}

.three-tier .content .group-tabs {
    margin-top: 20px !important;
    margin-left: 5px !important;
}

.three-tier .content .group-links {
    float: right;
    margin-top: 12px;
    margin-right: 20px;
}

    .three-tier .content .group-links span {
        margin-right: 20px;
        cursor: pointer;
    }

        .three-tier .content .group-links span:hover {
            border-bottom: 1px solid black;
            color: darkblue;
            font-weight: bold;
        }

        .three-tier .content .group-links span.active {
            border-bottom: 1px solid black;
            color: darkblue;
            font-weight: bold;
        }

.three-tier .footer {
    background-color: white;
    padding: 10px;
    overflow: hidden;
    text-align: center;
}

/* Dossier Styles */
.title-header {
    background: #EEE !important;
}

    .title-header[aria-label="title"] {
        background: #FFF !important;
    }

.mstrd-FilterSummaryBarItem-title {
    font-size: 12px !important;
}

.mstrd-FilterSummaryBarItem-summaryText {
    font-size: 12px !important;
}

.mstrd-FilterSummaryBar-filterCount {
    font-size: 12px !important;
}

.mstrd-FilterSummaryBar {
    height: 28px !important;
}

.mstrd-FilterSummary-barContainer {
    height: 28px !important;
}


.myFrame iframe {
}

.myFrame {
    display: block;
    height: calc(100vh - 150px);
}