.alertSubscriptionWidgetContainer {
    margin-bottom: 10px;
}

.alertSubscriptionWidgetContainer .header {
    color: #3a87d3;
    font-weight: bold;
    background: #e7eef1;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
    border: 1px solid #bddbfa;
    border-radius: 3px;
}

.alertSubscriptionWidgetContainer table {
    padding: 10px 10px 10px;
}

.alertSubscriptionWidgetContainer table thead {
    padding: 10px;
    /*background: #3473be;*/
    background: #479bd7;
    border: 1px solid transparent;
    border-radius: 5px;
}


.alertSubscriptionWidgetContainer table th {
    color: #fff;
    padding: 15px 0 15px 10px;
}

.alertSubscriptionWidgetContainer table td {
    border-bottom: 1px solid lightgray;
}

.alertSubscriptionWidgetContainer table td button {
    border: 1px solid transparent;
    border-radius: 5px;
    color: white !important;
    padding: 5px;
    text-transform: capitalize;
    margin: 5px;
    width: 75px;
    font-size: 11px;
}