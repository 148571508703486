a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


@media all and (min-width: 992px) {
    .dropdown-menu li {
        position: relative;
    }

    .nav-item .submenu {
        display: none;
        position: absolute;
        left: 100%;
        top: -7px;
    }

    .nav-item .submenu-left {
        right: 100%;
        left: auto;
    }

    .dropdown-menu > li:hover {
        background-color: #f1f1f1
    }

        .dropdown-menu > li:hover > .submenu {
            display: block;
        }
}
/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
    .dropdown-menu .dropdown-menu {
        margin-left: 0.7rem;
        margin-right: 0.7rem;
        margin-bottom: .5rem;
    }
}

/* Dropdown Button */
.dropbtn {
    background-color: #479BD7;
    color: #ececec;
    font-size: 13px !important;
    border: none;
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content-nav {
    display: none;
    position: absolute;
    font-size: 13px !important;
    background-color: #f1f1f1;
    min-width: 160px;
    width: max-content;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

    /* Links inside the dropdown */
    .dropdown-content-nav a {
        color: #268bd2;
        padding: 8px 15px;
        text-decoration: none;
        display: block;
    }

        /* Change color of dropdown links on hover */
        .dropdown-content-nav a:hover {
            background-color: #ddd;
        }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content-nav {
    display: block;
    background-color: #fff !important;
}

